.recordings {
    display: flex;
    flex-wrap: wrap;
    margin: 10px;
    padding: 10px
}


.recording-card {
    /* display: flex; */
    flex-direction: column;
    width: 400px;
    height: 350px;
    overflow: hidden;
    margin: 10px
}

.recording-card a {
    text-decoration: none;
    margin: 0;
    /* Remove underline from links within .recording-card */
}

.image-container-recording {
    position: relative;
    width: 100%;
    height: 60%;
}

.image-container-recording img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Maintain aspect ratio and cover container */
}

.text-container-recording {
    /* padding: 10px; */
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
    color: #696136;
    text-decoration: none;

}

.recording-text {
    font-size: 20px;
    font-weight: bolder;
    text-decoration: none;
}

@media screen and (max-width: 500px) {

    .recordings {
        margin: 0;
        padding: 0
    }

    .recording-card {
        width: 100%;
        margin: 0;
    }

    .slide-card {
        width: 350px;
        margin: 10px;
    }

}