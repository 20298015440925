.recording-title {
    color: #696136;
    font-weight: bolder;
}



video {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    display: block;
    margin-left: 0;
    padding-bottom: 50px;
}

.recording-container {
    width: 50%;
}

@media screen and (max-width: 1000px) {
    .recording-container {
        width: 100%;
    }
}