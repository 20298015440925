.space-dec {
    padding-bottom: 40px;
    color: rgba(105, 97, 54, 1);
}

.discounts-background {
    background-color: #E6E4DD;
    /* height: 200px; */
    /* width: 100%; */
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 32px;
}

.discounts-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.discounts-title {
    font-size: 18px;
    font-weight: bolder;
}

.discounts-text {
    color: #696136;
    width: 30%;
}

@media screen and (max-width: 1000px) {

    .discounts-content {
        display: inline;
        align-items: center;
    }

    .discounts-text {
        width: 100%;
    }

}

@media screen and (max-width: 500px) {}