.tablet-container {
    color: #696136;
    font-size: 14px;
    font-weight: bold;
    /* width: 50px; */
    padding: 10px;
    background-color: #E6E3D9;
    border-radius: 20px;
    margin: 5px;
    display: inline-block;
    padding: 5px 10px;

}

.tablet-container.selected {
    background-color: white;
    color: #696136;
    border: 1px solid #696136;
    /* Add any additional styles for the selected state */
}