.square-with-background-directory {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}



.text-container-directory {
    /* padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px; */
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    background-color: rgba(0, 0, 0, 0.2);
    overflow: hidden;
    /* padding: 5px; */
    border-radius: 10px;
}

.text {
    color: white;
    font-size: 15px;
    /* height: 10px; */
    margin: 0;
    /* Add spacing between lines */
}

.textLarge {
    color: white;
    font-size: 20px;
    /* height: 20px; */
    margin: 0;
    /* Add spacing between lines */
}


.pill-container {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.pill-background {
    display: inline-flex;
    align-items: center;
    color: #696136;
    font-weight: bolder;
    padding: 8px 12px;
    border-radius: 20px;
    background-color: #e0e0e0;
    margin-bottom: 10px;
}