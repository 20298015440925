.guestlist-modal {

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;

}

.guestTitle {
    font-size: 30px;
    font-weight: bolder;
}

.guestlist-content {
    background-color: #fff;
    padding: 20px;
    width: 80%;
    height: 80%;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    overflow-y: auto;
    position: relative;

}

.attendee-list {
    list-style: none;
    /* Remove bullet points */
    padding: 0;
    /* Remove default padding */
}

.attendee-item {
    height: 40px;
    /* Set the height of each list item */

    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    ;
    padding: 10px;
    /* margin-bottom: 10px; */
    /* Add margin between list items */
}

.attendee-name {
    font-weight: bold;
    color: #696136;
    font-size: 16px;
    /* Optionally make the name bold */
}

.attendee-company {
    cursor: pointer;
}

.attendee-company:hover {
    text-decoration: underline;

}

.company-link {
    cursor: pointer;

}

.company-link:hover {
    text-decoration: underline;

}