.event-slide {
    /* padding-left: 140px;
    padding-right: 140px; */
    overflow: hidden;
    overflow-x: scroll;
    display: flex;
    justify-content: stretch;
    font-family: "Futura-Light", sans-serif;
    padding-bottom: 100px;
}

.event-card {
    background-color: white;
    border-radius: 20px;
    margin: 10px;
    flex: 0 0 auto;

    /* min-width: 300px; */
    width: 300px;
    height: 600px;
    /* width: fit-content; */

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
}

.event-buttons {
    display: flex;

}

.event-title {
    padding: 5px;
    /* background-color: purple; */
    /* height: 80px; */
    /* text-align: center; */
    justify-content: center;
    align-items: center;
}

.event-title-container-123 {
    height: 100px;
    justify-content: center;
    align-items: center;
}

.event-image {
    width: 100%;
    height: 40%;
    border-radius: 20px 20px 0 0;
    object-fit: cover;
    margin: 0;
}

.format-details {
    padding-left: 10px;
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0%;
}

.event-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0%;
}

.details-container-all {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: rgb(105, 97, 54);
    /* padding-top: 20px;
    padding-bottom: 20px; */
    padding-right: 20px;
}


.details-container {
    display: flex;
    align-items: center;
    padding-right: 20px;
    color: rgb(105, 97, 54);
    margin: 0;
}

.details-icon-container {
    display: flex;
    align-items: center;
    padding-right: 20px;
    color: rgb(105, 97, 54);
    margin: 0;
}

.event-icons {
    width: 20px;
    height: auto;
    margin-right: 10px;
}

.event-description {
    width: 550px;
    color: rgb(105, 97, 54);
    font-size: 18px;
    padding-bottom: 20px;
    line-height: 25px;
}

.view-button {
    height: 36px;
    width: 134px;
    background-color: white;
    color: black;
    border-radius: 82px;
    display: flex;
    margin: 5px;
    margin-top: 40px;
    justify-content: center;
    align-items: center;
    font-family: "Futura-Light", sans-serif;
    text-decoration: none;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
    border: 1px solid black;
}

.view-button:hover {
    background-color: black;
    color: white;
    border-radius: 82px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Futura-Light", sans-serif;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

}

.information-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.seemore-button {
    height: 36px;
    width: 134px;
    background-color: #F4F2EB;
    color: #696136;
    border-radius: 82px;
    display: flex;
    margin: 5px;
    justify-content: center;
    align-items: center;
    font-family: "Futura-Light", sans-serif;
    text-decoration: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border: 1px solid transparent;
    cursor: pointer;
}

.register-button {
    height: 36px;
    width: 134px;
    background-color: #F4F2EB;
    color: #696136;
    border-radius: 82px;
    display: flex;
    margin: 5px;
    justify-content: center;
    align-items: center;
    font-family: "Futura-Light", sans-serif;
    text-decoration: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border: 1px solid transparent;
    cursor: pointer;
    text-decoration: none
}

.register-button:hover {
    background-color: white;
    color: #696136;
    border-radius: 82px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Futura-Light", sans-serif;
    border: 1px solid #696136;

}

.guestlist-button {
    height: 36px;
    width: 134px;
    background-color: black;
    color: white;
    border-radius: 82px;
    display: flex;
    margin: 5px;
    justify-content: center;
    align-items: center;
    font-family: "Futura-Light", sans-serif;
    text-decoration: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border: 1px solid transparent;
    cursor: pointer;
}

.guestlist-button:hover {
    background-color: white;
    color: black;
    border-radius: 82px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Futura-Light", sans-serif;
    /* border: 1px solid #696136; */

}

.cancel-button {
    height: 36px;
    width: 134px;
    background-color: #EAECF0;
    color: black;
    border-radius: 82px;
    display: flex;
    margin: 5px;
    justify-content: center;
    align-items: center;
    font-family: "Futura-Light", sans-serif;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-decoration: none;
    border: 1px solid transparent;
}

.cancel-button:hover {
    height: 36px;
    width: 134px;
    background-color: white;
    color: #696136;
    border: 1px solid #696136;
}


h3 {
    font-weight: bolder;
    height: 30px;
    font-family: "Futura-Light", sans-serif;
    /* padding: 10px; */
}

.event-headers {
    padding-top: 20px;
    padding-bottom: 10px;
    font-size: 50px;
    width: 50%;
    color: rgb(105, 97, 54);
    font-family: "Futura-Light", sans-serif;
}

h5 {
    color: rgb(105, 97, 54);
    font-size: 20px;
    font-family: "Futura-Light", sans-serif;
}

p {
    font-family: "Futura-Light", sans-serif;
    margin: 0;
    padding: 5px;
}




/* EVENT DETAIL PAGE CSS */
.event-detail-image {
    width: 565px;
    height: 347px;
    object-fit: cover;
}

.event-page {
    padding-top: 100px;
    padding-left: 140px;
    background: linear-gradient(to bottom, #F4F2EB, #FFFF);
    padding-bottom: 200px;
}

.event-price {
    width: 100%;
    color: rgb(105, 97, 54);
    font-size: 16px;
    padding-bottom: 50px;
}

@media screen and (max-width: 1000px) {

    .event-slide {
        /* padding-left: 32px;
        padding-right: 32px; */
        overflow: hidden;
        overflow-x: scroll;
        display: flex;
        justify-content: stretch;
        font-family: "Futura-Light", sans-serif;
    }

    .event-card {}

    .event-headers {
        font-size: 35px;
        width: 100%;
        overflow: hidden;
        font-family: "Futura-Light", sans-serif;
    }

    .event-detail-image {
        width: 100%;
        /* height: 347px; */
        object-fit: cover;
    }

    .event-page {
        padding-top: 100px;
        padding-left: 32px;
        padding-right: 32px;
        background: linear-gradient(to bottom, #F4F2EB, #FFFF);
        padding-bottom: 50px;

    }

    .event-description {
        width: 100%;
        color: rgb(105, 97, 54);
        font-size: 16px;
        padding-bottom: 50px;
    }



    .details-icon-container {
        /* display: flex; */
        align-items: center;
        padding-right: 20px;
        color: rgb(105, 97, 54);
    }

    .details-container-all {
        /* display: flex; */
        align-items: center;
        color: rgb(105, 97, 54);
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 20px;
    }

}

/* SPINNER CSS */
.spinner {
    position: fixed;
    /* top: 50%; */
    left: 50%;
    /* transform: translate(-50%, -50%); */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* width: 100vw; */
}