/* Navbar.css */
.navbar {
    display: block;
    /* justify-content: space-between; */
    align-items: center;
    background-color: white;
    padding: 0.8rem;
    position: fixed;
    width: 100%;
    /* height: 72px; */
    z-index: 999;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.nav-container {
    display: flex;

    align-items: center;
    justify-content: space-between;
    width: 95%;
    padding: 0 1rem;
    /* Add padding for spacing */

}

.nav-links {
    list-style: none;
    display: flex;
    justify-content: start
}

.nav-links li {
    padding: 0.5rem;
}

.nav-links li a {
    color: black;
    text-decoration: none;
}

.nav-links-mobile {
    list-style: none;
    /* display: flex; */
    justify-content: start
}

.nav-links-mobile li {
    padding: 0.5rem;
}

.nav-links-mobile li a {
    color: black;
    text-decoration: none;
}

.active-page {
    /* li { */
    font-weight: bolder;
    color: black;
    /* border-bottom: 2px solid red; */

}


.logo-container {
    display: flex;
    align-items: center;
}

.logo {
    height: 30px;
    margin-right: 50px;
}

.logout-container {
    font-weight: bold;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 80%;
    padding-right: 50px;

}

.black-button-logout {
    height: 36px;
    width: 115px;
    background-color: #F4F2EB;
    color: #696136;
    border-radius: 82px;
    display: flex;
    margin-right: 50px;

    justify-content: center;
    align-items: center;
    font-family: 'Futura Book BT', sans-serif;
    text-decoration: none;
    border: 1px solid transparent;

}

.black-button-logout:hover {
    background-color: white;
    color: #696136;
    border-radius: 82px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Futura Book BT', sans-serif;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border: 1px solid #696136;
}

.activeLink {
    font-weight: bolder;
    color: red !important;

}

/* Hamburger Icon */
.hamburger-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 25px;
    height: 15px;
    cursor: pointer;
    display: none;
}

.line {
    width: 100%;
    height: 2px;
    background-color: black;
}

.mobile-menu {
    padding-left: 0;
}


/* Add CSS media queries for responsive design */
@media screen and (max-width: 1000px) {
    .nav-links {
        display: none;
        /* Hide the regular nav links */
    }

    .logout-container {
        padding-right: 100px;
    }

    .nav-links-container {
        margin-top: 20px;
        /* Add spacing between nav links and mobile menu */
    }

    .black-button-logout {
        margin-right: 20px;
        padding: 2px;
        margin-left: 0;
        align-items: center;
    }

    .nav-container {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-right: 10px;
    }

    .mobile-menu {
        margin-top: 20px;
        /* Add spacing between mobile menu and nav links */
    }

    .hamburger-icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 25px;
        height: 15px;
        cursor: pointer;
    }

}