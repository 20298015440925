.black-button {
    height: 56px;
    width: 200px;
    background-color: black;
    color: white;
    border-radius: 82px;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;

    justify-content: center;
    align-items: center;
    font-family: 'Futura Book BT', sans-serif;
    text-decoration: none;

}

.black-button:hover {
    height: 56px;
    width: 200px;
    background-color: white;
    color: black;
    border-radius: 82px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Futura Book BT', sans-serif;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.black-button-bottomright {
    height: 56px;
    width: 200px;
    background-color: black;
    color: white;
    border-radius: 82px;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 20px;

    justify-content: center;
    align-items: center;
    font-family: 'Futura Book BT', sans-serif;
    text-decoration: none;
    /* position: absolute;
    bottom: 0;
    right: 0; */
}

.black-button-bottomright:hover {
    height: 56px;
    width: 200px;
    background-color: white;
    color: black;
    border-radius: 82px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Futura Book BT', sans-serif;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.portal-decoration {
    background-color: white;
    padding-left: 140px;
    padding-right: 140px;
    padding-bottom: 100px;
    padding-top: 200px;
    overflow: hidden;

}

@media screen and (max-width: 1000px) {

    .portal-decoration {
        background-color: white;
        padding-left: 10px;
        padding-right: 10px;
        margin-right: 0;
        padding-bottom: 80px;
        padding-top: 100px;

    }

    .discounts-content {
        display: inline;
        align-items: center;
    }

}

@media screen and (max-width: 500px) {

    .portal-decoration {}



}