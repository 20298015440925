.memberexplore-slide {
    /* padding-left: 140px;
    padding-right: 140px; */
    /* width: 200vw; */
    width: auto;
    overflow: hidden;
    overflow-x: scroll;
    display: flex;
    justify-content: flex-start;
    font-family: "Futura-Light", sans-serif;
    /* padding-bottom: 100px; */
}


.memberexplore-card {
    /* display: flex; */
    flex-shrink: 0;
    flex-direction: column;
    width: 212px;
    height: 265px;
    overflow: hidden;
    margin: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.image-container-memberexplore {
    /* position: relative; */
    width: 100%;
    height: 60%;
}

.image-container-memberexplore img {
    width: 100%;
    height: 100%;
    object-fit: cover
        /* Maintain aspect ratio and cover container */
}

.memberexplore-text {
    font-size: 20px;
    font-weight: bolder;
    text-decoration: none;
    color: black;
    padding: 10px;
}

.memberexplore-text:hover {
    text-decoration: underline;
}

.company-text {
    padding: 10px;
    text-decoration: none;
}

.company-text:hover {
    text-decoration: underline;
}

.company-link {

    text-decoration: none;
    color: black;
}

.company-link:hover {
    text-decoration: underline;
}