/* Add this CSS code to your restaurants.css file */

.restaurant-slide {
    display: flex;
    overflow-x: auto;
}

.restaurant-card {
    display: flex;
    flex-direction: column;
    /* Stack children elements vertically */
    margin-right: 10px;
    margin-left: 10px;
    position: relative;
    /* optional: add some spacing between the cards */
}

.restaurant-image {
    position: relative;
    /* Needed to make absolute positioning work correctly */
    width: 314px;
    /* adjust the width as needed */
    height: 126px;
    /* adjust the height as needed */
    object-fit: contain;
    /* optional: maintain aspect ratio of the image */
}

.format-details-restaurant {
    position: absolute;
    top: 50%;
    /* Position the div 50% from the top of the parent (image) */
    left: 50%;
    /* Position the div 50% from the left of the parent (image) */
    transform: translate(-100%, 0%);
    /* Center the div both horizontally and vertically */
    width: 136px;
    height: 40px;
    background-color: white;
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    color: rgba(105, 97, 54, 1);
    border-radius: 82px;
    font-family: 'Futura Book BT', sans-serif;
    text-decoration: none;
    z-index: 99;
}

/* Update this selector to target the p element */
.restaurant-text {
    margin: 0;
    /* Remove any default margins to avoid extra spacing */
    text-decoration: none;
}

@media screen and (max-width: 1000px) {

    .restaurant-card {
        display: flex;
        flex-direction: column;
        /* Stack children elements vertically */
        margin: 0;
        position: relative;
        /* optional: add some spacing between the cards */
    }

}