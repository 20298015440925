.profile-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
}

.col {
    width: 40%;

}

.col2 {
    width: 100%;

}


.square-with-background {
    position: relative;
    width: 300px;
    /* Set your desired square size */
    height: 300px;
    background-color: rgba(0, 0, 0, 0.5);
    /* Set your desired square size */
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(0, 0, 0, 0.5); */
    /* margin: 0; */
    /* 50% black overlay */
}


.text-container {
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    position: absolute;
    bottom: 0;
    width: 290px;
    height: 20%;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: left;
}

.text {
    color: white;
    font-size: 15px;
    height: 10px;
    margin: 0;
    /* Add spacing between lines */
}

.profile-text {
    color: black;
    font-size: 15px;
    text-align: center;
    /* width: 300px; */
    margin: 0;
}

.profile-text-bio {
    color: black;
    font-size: 15px;
    width: 75%;
    margin: 0;
}

.question-text {
    color: #696136;
    font-size: 15px;
    padding-left: 10px;
    /* Add any additional styling you want for the text */
    margin: 0;
    /* Add spacing between lines */
}

.horizontal-line-divider hr {
    border: none;
    border-top: 1px solid #ccc;
    width: 300px;
    /* Adjust the color and thickness as needed */
    margin: 10px 0;
    /* Adjust the margin to control the space above and below the divider */
}

.vertical-line-divider {
    width: 1px;
    height: 500px;
    /* Adjust the height of the vertical line */
    background-color: #ccc;
    /* Adjust the color of the vertical line */
    margin: 0 10px;
    /* Adjust the margin to control the space on the sides of the divider */
}

.headline-information {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 150px;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.headline-container {
    display: flex;
    flex-direction: row;
    width: 300px;
    /* background-color: black; */
}

@media screen and (max-width: 1000px) {

    .col {
        width: 100%;
        /* background-color: purple; */
    }

    .profile-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-around;
    }

    .square-with-background {
        /* width: 100%; */
    }

}


@media screen and (max-width: 500px) {

    .col {
        width: 100%;
    }

    .profile-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-around;
    }

    .square-with-background {
        width: 100%;
    }

    .overlay {
        width: 100%;
    }

    .text-container {
        width: 96%;
    }

    .horizontal-line-divider hr {
        width: 100%;
    }

    .headline-container {
        width: 100%;
    }

    .headline-information {
        width: 100%;
    }

    .profile-text-bio {
        width: 100%;
    }

}