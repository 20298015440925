.tab-titles {
    display: flex;
    /* justify-content: space-between; */
    margin-bottom: 10px;
}

.tab-title {
    background: none;
    border: none;
    padding: 5px;
    font-weight: normal;
    cursor: pointer;
    font-size: 17px;
    font-family: 'Futura Book BT', sans-serif;
    color: black;
}

.tab-title.active {
    font-weight: bold;
    text-decoration: underline;
}