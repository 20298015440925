/* Login.css */
.login-container {
    display: flex;
    justify-content: left;
    align-items: left;
    /* height: 100vh; */
}

.space-dec-signup {
    padding-bottom: 40px;
    color: rgba(105, 97, 54, 1);
    /* width: 100%; */
    align-items: left;
    justify-content: left;
    text-align: left;
    width: 100%;
    /* min-width: 200px */
}

.login-container-signup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px;
}

.exit-button-signup {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: #333;
    /* Cross color */
    font-size: 24px;
    z-index: 1000;
}

.input-field {
    width: 100%;
    padding: 10px;
    margin: 8px 0;
    border: none;
    border-bottom: 1px solid #000;
    font-size: 16px;
    color: #000;
    background-color: transparent;
    outline: none;
    font-family: "Futura-Light", sans-serif;
}

/* Optional: Add hover effect for the pill container */
.login-pill:hover {
    border-color: #333;
}

.format-signup {
    justify-content: center;
    align-items: center;

    /* width: 100%; */
}

.signup-button {
    height: 56px;
    width: 200px;
    background-color: black;
    color: white;
    border-radius: 82px;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;

    justify-content: center;
    align-items: center;
    font-family: 'Futura Book BT', sans-serif;
    text-decoration: none;

}

.signup-button:hover {
    height: 56px;
    width: 200px;
    background-color: white;
    color: black;
    border-radius: 82px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Futura Book BT', sans-serif;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.forgot-password {
    font-size: 12px;
    font-weight: bolder;
    /* font-family: "Futura-Medium", sans-serif; */
}

.forgot-password:hover {
    font-size: 12px;
    font-weight: bolder;
    text-decoration: underline;
    /* font-family: "Futura-Medium", sans-serif; */
}