.profile-container {
    display: flex;
    flex-direction: row;

    justify-content: space-around;
}

.membership-card {
    position: relative;
}


.membership-card-image {
    width: 300px;
    height: 200px;

}



.membership-card-container {
    position: relative;
    /* Set the container as a positioned element */
    z-index: 1;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    bottom: 0;
    height: 80%;
    display: flex;

    /* justify-content: flex-end; */
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: column;
}

.membership-card-text {
    color: black;
    font-size: 15px;
    margin: 0;
    padding-bottom: 25px;
}

.membership-card-small-text {
    color: black;
    font-size: 10px;
    margin: 0;
}


.membership-number {
    color: black;
    font-size: 15px;
    margin: 0;
    text-align: right;
}

.small-bold-text {
    font-size: 12px;
    font-weight: bold;
    color: #696136;
    margin: 0;
}