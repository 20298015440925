.directory {
    padding-top: 200px;
    padding-left: 140px;
    padding-right: 140px;
    background: linear-gradient(to bottom, #D4B69D, #F4F2EB);
    min-height: 100vh;


}

.members {
    display: flex;
    flex-wrap: wrap;
    margin: 10px;
    padding: 10px;

}

.member-card {
    /* margin: 10px; */
    margin: 5px;
    width: 226px;
    height: 250px;

    /* background-color: rgba(0, 0, 0, 0.5); */
}

.filter-container {
    display: flex;
    flex-wrap: wrap;
    /* Wrap items to a new line if they exceed container width */
}

.filter-container.show {
    display: inline-block;
    /* Additional styling here */
}

.filter-dropdown {
    padding-right: 5px;
    width: 200px;
    /* Add some spacing between dropdowns */
    display: inline-block;
    /* Display dropdowns inline */
}

.filter-label {
    font-size: 14px;
    color: #333;
}

.mobileFilter {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.mobileFilterButton {
    display: none;
}

.search-container input[type="text"] {

    background-color: transparent;
    border: none;
    outline: none;
    padding: 5px 10px;
    border-radius: 5px;
    color: #333;
    /* Adjust the text color as needed */
    font-size: 14px;
    /* Adjust the font size as needed */
    width: 200px;
    /* Adjust the width as needed */
    font-family: "Futura-Light", sans-serif;
}

/* Remove the default blue highlight on selection */
.search-container input[type="text"]:focus {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
}

@media screen and (max-width: 1000px) {



    .mobileFilterButton {
        display: inline-block;
    }

    .filter-container {
        display: none;
    }

    .directory {
        padding-top: 100px;
        padding-left: 16px;
        padding-right: 16px;


    }

    .members {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin: 0px;
        padding: 5px;
        /* background-color: purple */
    }

    .member-card {
        /* margin: 10px; */
        /* padding: 5px; */
        width: 150px;
        height: 200px;
        /* background-color: rgba(0, 0, 0, 0.5); */
    }

}

@media screen and (max-width: 500px) {

    .filter-dropdown {
        width: 45%;
    }


    .members {
        display: flex;
        flex-wrap: wrap;
        /* width: 100%; */
        /* justify-content: space-evenly; */
        margin: 0px;
        padding: 5px;
        /* background-color: purple */
    }

    .member-card {
        /* margin: 10px; */
        /* padding: 5px; */
        width: 175px
            /* background-color: rgba(0, 0, 0, 0.5); */
    }

    .search-container input[type="text"] {

        color: #232323;
    }

}