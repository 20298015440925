/* EditProfilePopup.css */
.edit-profile-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.exit-button {
    cursor: pointer;
    font-size: 20px;
    color: #000;
}

.exit-cross {
    font-weight: bold;
}

.profile-pic-label {
    margin-top: 10px;
}

.edit-profile-content {
    background-color: #fff;
    padding: 20px;
    width: 80%;
    height: 80%;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    overflow-y: auto;
    position: relative;

}

.vstack-item {
    margin-bottom: 20px;
    margin-top: 20px;
}

.input-label {
    display: block;
    text-align: left;
    padding-left: 8px;
    margin-bottom: 5px;
    font-size: 14px;
    color: #696136;
}

.textfield-columns {
    display: flex;
    flex-wrap: wrap;

}

.textfield-container {
    border: 1px solid rgba(105, 97, 54, 0.5);
    padding: 8px;
    margin-top: 10px;
    margin-right: 10px;
    height: 60px;
    flex: 0 0 45%;
    /* This will make each container take 50% width (2 columns) */
    /* padding: 10px; */
    /* box-sizing: content-box; */
    box-sizing: border-box;
}

.textfield-container-bio {
    border: 1px solid rgba(105, 97, 54, 0.5);
    padding: 8px;
    margin-top: 10px;
    margin-right: 10px;
    height: 60px;
    flex: 0 0 93%;
    /* This will make each container take 50% width (2 columns) */
    /* padding: 10px; */
    /* box-sizing: content-box; */
    box-sizing: border-box;
}

.profile-input {
    font-size: 16px;
    width: 100%;
}

.profile-input input {
    border: none;
    outline: none;
    padding-left: 8px;
    width: 95%;
    font-size: 15;
    font-family: "Futura-Light", sans-serif;
}